import React from 'react'

const initialState = {
  lang: 'en',
  translations: {},
  isDefaultLanguage: false
}

export const LanguageContext = React.createContext(initialState)

export const LanguageProvider = ({ children, ...props }) => (
  <LanguageContext.Provider value={{ ...props }}>
    {children}
  </LanguageContext.Provider>
)

export default LanguageProvider

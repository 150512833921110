import React from 'react'
import { Flex } from 'rebass'

const List = (props) => {
  return (
    <Flex
      p={0}
      as="ul"
      sx={{
        listStyleType: 'none'
      }}
      flexDirection="column"
      {...props}
    />
  )
}

export default List

import React from 'react'
import styled from '@emotion/styled'
import { Flex, Box, Heading, Text } from 'rebass'
import Container from './Container'
import Navigation from './Navigation'
import TextLink from './TextLink'
import List from './List'
import ListItem from './ListItem'
import Divider from './Divider'

const Footer = styled(Box)`
  a.active, a:hover, a:focus {
    text-decoration: underline;
    color: ${props => props.theme.colors.text.light};
  }
`

const PageFooter = ({ translations: t = {}, apartments = [] }) => {
  return (
    <Box
      py={2}
      as="footer"
      bg="bg.dark"
    >
      <Container>
        <Footer>
          <Flex
            mt={4}
            mx={-3}
            flexWrap="wrap"
            color="text.light"
            flexDirection="row"
          >
            <Box px={4} py={2} width={[1, 1 / 2, 1 / 3]}>
              <Heading
                as="h4"
                fontSize={3}
              >
                {t.section.footer.about.title}
              </Heading>
              <Divider mt={2} />
              <Text my={3} color="rgba(255, 255, 255, .7)">
                {t.section.footer.about.description}
              </Text>
            </Box>
            <Box px={4} py={2} width={[1, 1 / 2, 1 / 3]}>
              <Heading
                as="h4"
                fontSize={3}
              >
                {t.section.footer.apartments.title}
              </Heading>
              <Divider mt={2} />
              <List my={3}>
                {apartments.map((apartment, i) => (
                  <ListItem key={i} pb={2}>
                    <TextLink to={apartment.path}>
                      {apartment.pageTitle}
                    </TextLink>
                  </ListItem>
                ))}
              </List>
            </Box>
            <Box px={4} py={2} width={[1, 1 / 2, 1 / 3]}>
              <Heading
                as="h4"
                fontSize={3}
              >
                {t.section.footer.quickLinks.title}
              </Heading>
              <Divider mt={2} />
              <Box my={3}>
                <Navigation />
              </Box>
            </Box>
          </Flex>
        </Footer>
      </Container>
    </Box>
  )
}

PageFooter.propTypes = {
}

export default PageFooter

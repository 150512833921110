import { Box } from 'rebass'
import styled from '@emotion/styled'
import { borderColor } from 'styled-system'

const Divider = styled(Box)`
  ${borderColor}
`

Divider.defaultProps = {
  as: 'hr',
  borderColor: 'secondary.light'
}

export default Divider

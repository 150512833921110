import { exact, string, number } from 'prop-types'

export const fixedImage = exact({
  width: number,
  height: number,
  src: string,
  srcSet: string,
  srcWebp: string,
  srcSetWebp: string,
  base64: string
}).isRequired

import React from 'react'
import { Link } from 'rebass'
import { Link as GatsbyLink } from 'gatsby'

const LocalizedLink = ({ partiallyActive, to, ...props }) => {
  return (
    <GatsbyLink
      {...props}
      as={Link}
      to={to}
      partiallyActive={to === '/' ? undefined : partiallyActive}
    />
  )
}

export default LocalizedLink

import React from 'react'
import { navigate } from 'gatsby'
import { Text } from 'rebass'
import List from './List'
import ListItem from './ListItem'
import { ExternalLink } from './TextLink'
import { LanguageContext } from './LanguageContext'
import { allowedLanguages } from '../i18n-config'

const LanguagePicker = ({ paths = {} }) => {
  const { lang: currentLanguage, translations } = React.useContext(LanguageContext)

  return (
    <List flexDirection="row">
      {allowedLanguages.map((lang) => {
        const localizedPath = paths[lang]
        const isActive = lang === currentLanguage

        return (
          <ListItem
            ml={[2, 4]}
            key={lang}
          >
            <ExternalLink
              title={lang}
              href={localizedPath}
              onClick={(e) => {
                e.preventDefault()
                navigate(localizedPath, {
                  replace: true
                })
              }}
              active={isActive}
              activeClassName="active"
            >
              <Text
                as="span"
                fontSize={1}
                variant="caps"
              >
                {translations.languages[lang]}
              </Text>
            </ExternalLink>
          </ListItem>
        )
      })}
    </List>
  )
}

export default LanguagePicker

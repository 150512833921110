import React from 'react'
import { Box } from 'rebass'

const Container = (props) => {
  return (
    <Box
      sx={{
        maxWidth: 1015,
        mx: 'auto',
        px: 3,
      }}
      {...props}
    />
  )
}

export default Container

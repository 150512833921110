import React from 'react'
import { exact, object, string } from 'prop-types'
import Seo from './Seo'
import PageFooter from './PageFooter'
import LanguageProvider from './LanguageContext'
import { fixedImage } from '../propTypes'
import { allowedLanguages } from '../i18n-config'

const [defaultLanguage] = allowedLanguages

const Layout = ({ children, translations = {}, seo = {}, apartments = [], contactInfo = null }) => {
  return (
    <LanguageProvider
      lang={seo.lang}
      translations={translations}
      isDefaultLanguage={seo.lang === defaultLanguage}
    >
      <Seo {...seo} />
      <main>
        {children}
      </main>
      <PageFooter
        apartments={apartments}
        contactInfo={contactInfo}
        translations={translations}
      />
    </LanguageProvider>
  )
}

Layout.propTypes = {
  seo: exact({
    lang: string,
    title: string,
    description: string,
    shareImage: fixedImage,
  }),
  translations: object,
}

export default Layout

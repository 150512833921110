import styled from '@emotion/styled'
import { color } from 'styled-system'
import { Link as RebassLink } from 'rebass'
import Link from './Link'

const TextLink = styled(Link)`
  ${({ theme }) => theme.variants.link};
  ${color};
`

TextLink.defaultProps = {
  color: 'inherit',
  activeClassName: 'active',
}

export const ExternalLink = styled(RebassLink)`
  ${({ active, theme }) => active ? theme.variants.activeLink : theme.variants.link};
`

ExternalLink.defaultProps = {
  target: '_self',
  rel: 'noopener noreferrer',
}

export default TextLink

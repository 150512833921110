import React from 'react'
import { Box } from 'rebass'

const ListItem = (props) => {
  return (
    <Box
      p={0}
      as="li"
      {...props}
    />
  )
}

export default ListItem

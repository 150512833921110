import styled from '@emotion/styled'
import { Flex } from 'rebass'

const ImgOverlay = styled(Flex)`
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
  top: 0; right: 0;
  bottom: 0; left: 0;
  align-items: center;
  justify-content: center;

  &:after {
    content: " ";
    opacity: 1;
    position: absolute;
    top: 0; right: 0;
    bottom: 0; left: 0;
    background-color: #080808;
    background-image: linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black),
    linear-gradient(-45deg, black 25%, transparent 25%, transparent 75%, black 75%, black);
    background-size: 2px 2px;
    opacity: .3;
  }
`

export default ImgOverlay

import { useStaticQuery, graphql } from 'gatsby'

export default function useSiteMetadata(title, description) {
  const { site } = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const metaTitle = title || site.siteMetadata.title
  const metaDescription = description || site.siteMetadata.description

  return {
    metaTitle,
    metaDescription
  }
}
